<template>
    <div style="display: flex;justify-content: center;align-items: center;height: 50%">
        <h1 class="animate__animated animate__flash" style="color: red;">您没有管理员权限，请联系管理员获取！</h1>
    </div>
</template>

<script>
export default {
    name: "adminError"
}
</script>

<style scoped>

</style>